<template>
  <div>
    <!-- check In File options -->
    <radio-group
      v-show="visibleOptions"
      @change="onCheckInOptionChange"
      :options="options"
      :select-option="selectOption"
    >
    </radio-group>

    <!-- Upload file input -->
    <template v-if="isSignedCheckin">
      <open-file-picker-upload @onFileChanged="onFileInputChange">
      </open-file-picker-upload>
    </template>

    <template v-else>
      <file-input
        @change="onFileInputChange"
        :progress-visible="visibleProgress"
        :progress-value="progress"
        :accept="inputFileTypes"
      >
        <!-- Option 1 (using slotProps) -->
        <!-- <template v-slot:progress="slotProps">-->
        <!--   {{ formatUploadFileProgressText(slotProps.value) }} %-->
        <!--  </template>-->

        <!-- Option 2 (Abbreviating Syntax ) -->
        <template v-slot="{ progress }">
          <slot name="progressDisplay">
            {{ formatProgressText(progress.value) }}
          </slot>
        </template>
      </file-input>
    </template>
  </div>
</template>

<script>
export default {
  name: "CheckInFile",
  components: {
    OpenFilePickerUpload: () =>
      import("@/components/shared/core/pickers/OpenFilePickerUpload"),
    FileInput: () => import("@/components/shared/core/inputs/FileInput"),
    RadioGroup: () => import("@/components/shared/core/radio/RadioGroup")
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [{ value: 0, text: "Option 0", disabled: false }]
    },
    selectOption: {
      type: Number,
      default: 0
    },
    progress: {
      type: Number,
      default: 0
    },
    inputType: {
      type: String,
      default: undefined
    },
    isSignedCheckin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * determine whether Options should be visible
     * @return {boolean}
     */
    visibleOptions() {
      return (this.options?.length ?? 0) > 0;
    },
    /**
     * determine whether Progress should be visible
     * @return {boolean}
     */
    visibleProgress() {
      return this.progress > 0;
    },

    /**
     * Allowed Input File Types
     * @return {String|string}
     */
    inputFileTypes() {
      return this.inputType ?? "*";
    }
  },
  methods: {
    /**
     * on Check In Option Change
     * @param {Number|number} option
     */
    onCheckInOptionChange(option) {
      this.$emit("checkInOptionChange", option);
    },

    /**
     * on File Input Change
     * @param file
     */
    onFileInputChange(file) {
      this.$emit("fileInputChange", file);
    },

    /**
     * format Progress Text
     * @param {Number|number} progress
     * @return {string}
     */
    formatProgressText(progress) {
      return `${progress} %`;
    }
  }
};
</script>
